import { Button, Link, Stack } from "@chakra-ui/react";
import { kratos, sdk } from "@digitalsurance/auth";
import { useEffect, useMemo, useState } from "react";
import { useSearchParam } from "react-use";
import { Flow } from "src/components/flow";
import { handleFlowError } from "src/hooks/errors";
import { useTranslation } from "src/hooks/useTranslation";
import { useStore } from "store/store";
import { navigate } from "vike/client/router";

export function Login() {
  const t = useTranslation();

  const [flow, setFlow] = useState<kratos.LoginFlow>();

  const flow_id = useSearchParam("flow");
  let return_to = useSearchParam("return_to");
  if (return_to) {
    return_to = return_to.replace("http:", "https:");
  }

  const refresh = useSearchParam("refresh");
  const aal = useSearchParam("aal");
  const basePath = useStore((s) => s.configuration.publicKratosUrl);

  const kratosClient = useMemo(
    () =>
      sdk.kratos.browser({
        basePath,
      }),
    [basePath]
  );

  useEffect(() => {
    if (flow || import.meta.env.SSR) {
      return;
    }
    if (flow_id) {
      kratosClient
        .getLoginFlow({ id: String(flow_id) })
        .then(({ data }) => {
          // We received the flow - let's use its data and render the form!
          setFlow(data);
        })
        .catch(handleFlowError("login", setFlow));
    } else {
      kratosClient
        .createBrowserLoginFlow({
          refresh: Boolean(refresh),
          aal: aal ? String(aal) : undefined,
          returnTo: return_to ? String(return_to) : undefined,
        })
        .then(({ data }) => {
          // We received the flow - let's use its data and render the form!
          setFlow(data);
        })
        .catch(handleFlowError("registration", setFlow));
    }
  }, [flow_id, return_to, flow, refresh, aal, kratosClient]);

  const onSubmit = (values: any) =>
    kratosClient
      .updateLoginFlow({
        flow: String(flow?.id),
        updateLoginFlowBody: values,
      })
      .then(({ data }) => {
        if (flow?.return_to) {
          window.location.href = flow.return_to;
          return new Promise<void>((r) => setTimeout(r, 10000));
        }
        // For now however we just want to redirect home!
        return navigate("/").then(() => { });
      })
      .catch(handleFlowError("login", setFlow))
      .catch((err: any) => {
        // If the previous handler did not catch the error it's most likely a form validation error
        if (err.response?.status === 400) {
          // Yup, it is!
          setFlow(err.response?.data);
          return;
        }

        return Promise.reject(err);
      });

  return (
    <Stack>
      <Stack>
        <Flow
          flow={flow}
          onSubmit={onSubmit}
          groups={["default", "password"]}
        />
      </Stack>
      <Stack>
        <Flow flow={flow} onSubmit={onSubmit} groups={["oidc"]} />
      </Stack>
      <Button
        mt={2}
        size="sm"
        variant="outline"
        colorScheme="gray"
        w="fit-content"
      >
        <Link href="/recovery" textDecor="none!important">
          {t("forgotPassword")}
        </Link>
      </Button>
    </Stack>
  );
}
/* <Link textDecor="none!important" href="/recovery">
        <Button>Recovery</Button>
      </Link> */
